const state_array = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  AA: "Armed Forces Americas",
  AE: "Armed Forces Europe, Canada, Africa and Middle East",
  AP: "Armed Forces Pacific",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  MH: "Republic of Marshall Islands",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export { state_array };

const nonames = ["llc", "lc", "pllc", "pa", "corp", "inc"];
export { nonames };

export const KEY_ORDER_INFO = "orderInfo";
export const KEY_PAYMENT_INFO = "paymentInfo";
export const KEY_REFRESH = "isRefresh";
export const KEY_ORDER_AFTER_SUCCESS_PAYMENT = "oderInfoAfterSuccessPayment";
export const KEY_PRE_INC_ORDER_INFO = "preIncOrderInfo";
export const KEY_PRE_INC_ORDER_INFO_BACKUP = "preIncOrderInfoBackup";
export const KEY_INC_SUMMARY = "incSummary";
export const KEY_INC_SUMMARY_BACKUP = "incSummaryBackup";
export const KEY_UPSELL_ORDER_UPDATE = "upsellOrderUpdate";
export const KEY_STATE_LLC_UPSELL_PAGE = "infoUpSellPage";
export const KEY_TRADEMARK_UPSELL_INFO = "trademarkUpsellInfo";
export const KEY_TRADEMARK_UPSELL_ORDER_INFO = "trademarkUpsellOrderInfo";
export const KEY_UPSELL_BUCKET = "upsellBucket";

export const setItemLocalStorage = (key, value) => {
  if (!localStorage || !key) {
    return;
  }
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemLocalStorage = (key) => {
  if (!localStorage || !key) {
    return;
  }

  const value = localStorage.getItem(key);
  return JSON.parse(value);
};

export const setItemSessionStorage = (key, value) => {
  if (!sessionStorage || !key) {
    return;
  }
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getItemSessionStorage = (key) => {
  if (!sessionStorage || !key) {
    return;
  }

  const value = sessionStorage.getItem(key);
  return JSON.parse(value);
};

export const removeItemSessionStorage = (key) => {
  if (!sessionStorage || !key) {
    return;
  }

  sessionStorage.removeItem(key);
};

export const nameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][pP][aA]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);

export const llcNameRegExp = new RegExp(
  /(?!.*(?:[ ][pP][aA]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);

export const pscNameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))^[ a-zA-Z0-9&-]+$/
);

export const partnershipNameRegExp = new RegExp(
  /(?!.*(?:[ ][lL][cC]$))(?!.*(?:[ ][lL][lL][cC]$))(?!.*(?:[ ][pP][lL][lL][cC]$))(?!.*(?:[ ][cC][oO][rR][pP]$))(?!.*(?:[ ][iI][nN][cC]$))(?!.*(?:[ ][iI][nN][cC][oO][rR][pP][oO][rR][aA][tT][eE][dD]$))^[ a-zA-Z0-9&-]+$/
);
