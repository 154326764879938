import axios from "axios";

export const EMAIL_STATUS = {
  valid: "valid",
  invalid: "invalid",
  acceptAll: "accept_all",
  risky: "risky",
  unknown: "unknown",
  badRequest: "bad_request",
};

export const EMAIL_REASON = {
  verified: "verified",
  invalidSyntax: "invalid_syntax",
  invalidDomain: "invalid_domain",
  mailboxFull: "mailbox_full",
  invalidMailbox: "invalid_mailbox",
  unverifiable: "unverifiable",
  highRisk: "high_risk",
  complainer: "complainer",
  fraud: "fraud",
  blockAffiliate: "block_affiliate",
  blockByUser: "block_by_user",
  blockCountry: "block_country",
  noResult: "no_result",
};

export const VALIDATION_MESSAGE = {
  [EMAIL_REASON.verified]: "Verified!",
  [EMAIL_REASON.invalidSyntax]: "Syntax or spelling error",
  [EMAIL_REASON.invalidDomain]: "Domain does not exist or does not accept email",
  [EMAIL_REASON.mailboxFull]: "Mailbox is over quota and is currently rejecting email",
  [EMAIL_REASON.invalidMailbox]: "Mailbox doesn't exist",
  [EMAIL_REASON.unverifiable]: "Domain is a catch all and does not support validation",
  [EMAIL_REASON.highRisk]: "Address is a spamtrap, a known complainer or is suppressed",
  [EMAIL_REASON.complainer]: "Address has complained about receiving commercial email",
  [EMAIL_REASON.fraud]: "Address was used in a fraudulent transaction, e.g. credit card fraud",
  [EMAIL_REASON.blockAffiliate]: "The affiliate submitting data is blocked in your user settings",
  [EMAIL_REASON.blockByUser]: "This address is blocked in your user settings",
  [EMAIL_REASON.blockCountry]: "The request included an IP address parameter, and the IP is from a country that is blocked in your user settings",
  [EMAIL_REASON.noResult]: "A conclusive result could not be determined",
};

export const VALID_EMAIL_STATUSES = [
  EMAIL_STATUS.valid,
  EMAIL_STATUS.badRequest,
  EMAIL_STATUS.unknown,
];

export const INVALID_EMAIL_STATUSES = [
  EMAIL_STATUS.invalid,
  EMAIL_STATUS.acceptAll,
  EMAIL_STATUS.risky,
];

export default (email) => {
  return new Promise(resolve => {
    axios
    .post(`${process.env.NEXT_PUBLIC_REACT_APP_API}/customers/email/verify?email=${email}`)
    .then(response => {
      const data = response?.data ? { ...response?.data } : {};

      if (data?.reason === EMAIL_REASON.complainer || data?.reason === EMAIL_REASON.unverifiable) {
        data.status = EMAIL_STATUS.valid;
      }
      
      resolve({ ...data, status: data.status === "" ? EMAIL_STATUS.badRequest : data.status });
    })
    .catch(e => {
      resolve({ status: EMAIL_STATUS.valid });
    });
  });
};
